<template>
  <div class="amap-page-container">
    <el-amap
      ref="map"
      vid="amapDemo"
      :plugin="plugin"
      :mapStyle="mapStyle"
      :amap-manager="amapManager"
      :center="center"
      :zoom="zoom"
      :events="events"
      class="amap-demo"
    >
    </el-amap>
    <div style="background-color: transparent; width: 180px; position: absolute; z-index: 9999; top: 230px; right: 10px">
      <el-row>
        <dv-scroll-board @click="clickHandle" :config="config" style="width: 180px; height: 300px" />
      </el-row>
    </div>
  </div>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
let amapManager = new AMapManager() // 新建生成地图画布
import { queryList } from '@/api/project'
export default {
  data() {
    let self = this
    return {
      tableData: [],
      curProData: [],
      config: null,
      plugin: [
        // 'AMap.Scale', //  右下角缩略图插件 比例尺
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', //  地图工具条
        'AMap.MapType' //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      ],
      // ------------高德地图参数开始-----------------
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 12, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自定义地图样式，可以根据需求显示/隐藏内容，改变主题颜色等，具体的使用下面会写
      amapManager,
      center: [120.666708, 31.128737],
      events: {
        init(o) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initPro() // 绑定鼠标缩放事件
          })
        }
      }
      // ------------高德地图参数结束----------------
    }
  },
  mounted() {
    this.getProjectList()
  },
  methods: {
    getProjectList() {
      let params = {
        name: '',
        role: this.$store.getters.userType
      }
      queryList(params).then(res => {
        console.log(res)
        this.tableData = res.data.records
        let config = {
          header: ['项目名称'],
          data: [],
          index: true,
          indexHeader: '',
          columnWidth: [35],
          align: ['left'],
          waitTime: 2000,
          carousel: 'page'
        }
        res.data.records.forEach(item => {
          let a = []
          a.push('<a>' + item.name + '</a>')
          config.data.push(a)
          let obj = {
            province: item.province,
            city: item.city,
            county: item.district,
            labelOffset: [0, -12],
            lat: item.latitude, // 经度
            lng: item.longitude, // 纬度,
            orderCount: item.orderCount,
            address: item.address,
            name: item.name,
            status: item.status === 'OPEN' ? '已开工' : '未开工',
            state: 2
          }
          this.curProData.push(obj)
          // this.initPro()
        })
        lazyAMapApiLoaderInstance.load().then(() => {
          this.initPro() // 绑定鼠标缩放事件
        })
        this.config = config
      })
    },
    // -----------------高德地图开始----------
    // 初始化放大后项目的数据
    initPro() {
      let markers = []
      let _this = this
      let projectList = _this.curProData // 项目数据
      let map = _this.amapManager.getMap()
      if(projectList.length>0) {
        let r1 = projectList[0]
        if(r1.lng&r1.lat) {
          this.center = [r1.lng,r1.lat]
        }
      }
      map.clearMap()
      AMapUI.loadUI(['overlay/SimpleMarker'], function (SimpleMarker) {
        let color = ['#00F04F', '#01DAFF', '#0098E9', '#F3A100', '#F7666A', '#FCE800']
        // 已接入,波纹圆圈蓝色/黄色 0.1.2.3
        // 未接入 4.5
        projectList.forEach(element => {
          if (element.lng && element.lat) {
            markers.push(
              new SimpleMarker({
                // 定位点的样式
                showPositionPoint: {
                  color: element['state'] == 5 || element['state'] == 4 ? color[element['state']] : 'rgba(255,255,255,0)',
                  radius: element['state'] == 5 || element['state'] == 4 ? 5 : 0
                },
                // 自定义定位点,波纹感的圆点
                iconLabel: `${
                  element['state'] !== 4 && element['state'] !== 5
                    ? '<div class="example"><span class="dot colorStyle' + element.state + '"></span></div>'
                    : ''
                }`,
                //直接设置html(需要以"<"开头并且以">"结尾)
                map: map,
                position: [element.lng, element.lat], // 经纬度
                // 定位点的label标签
                label: {
                  offset: element['labelOffset'] ? new AMap.Pixel(+element['labelOffset'][0], +element['labelOffset'][1]) : '', // 修改label相对于marker的位置
                  // offset: new AMap.Pixel(0,0), // 修改label相对于marker的位置
                  content:
                    '<div class="makerLabel"><div style="display:flex"><span>' +
                    '项目名称：</span><span class="mapIcon_value" >' +
                    element.name +
                    '</span>' +
                    '</div>' +
                    '<div class="makerLabel"><div style="display:flex"><span>' +
                    '工单数：</span><span class="mapIcon_value" >' +
                    element.orderCount +
                    '</span>' +
                    '</div>' +
                    '<div style="display:flex"><span>项目地址:</span><span class="mapIcon_value">' +
                    element.address +
                    '</span> </div>' +
                    '<div style="display:flex"><span>状态:</span> <span class="mapIcon_value">' +
                    element.status +
                    '</span> </div>' +
                    '</div>'
                }
              })
            )
          }
        })
      })
    },
    // 绑定高德地图放大缩小map事件
    // changeMap() {
    //   this.initPro()
    // },
    clickHandle(v) {
      let projectId = this.tableData[v.rowIndex].id
      this.$router.push({ path: '/project/order', query: { id: projectId } })
    }
    // -----------------高德地图结束----------
  },
  created() {
    // this.addprojects() // 请求项目数据详情
  }
}
</script>
<style>
.amap-page-container {
  width: 100%;
  height: 100%;
}
/* 初始化定位标签样式 */
.amap-marker-label {
  vertical-align: middle;
  color: #555;
  background-color: #fffeef;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid #d1cece;
  width: auto;
  margin-left: -50px;
  top: -70px !important;
  border-radius: 5px;
}
.mapIcon {
  vertical-align: middle;
  color: #555;
  background-color: #fffeef;
  font-size: 12px;
  white-space: nowrap;
  position: relative;
  border: 1px solid #8e8e8e;
  width: auto;
  border-radius: 5px 5px 5px 0;
  height: 30px;
  line-height: 30px;
  top: 30px;
  left: 0px;
}
.makerLabel {
  line-height: 20px;
  width: 300px;
}
.mapIcon_value {
  /* margin-top: 20px; */
  position: relative;
  display: block;
  padding: 0px 5px 3px 10px;
  font-size: 14px;
  color: #0098e9;
  overflow: hidden;
  max-width: 230px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 5px 0 0 0;
}
/* .mapIcon_value::after {
  content:'...'
} */
.mapIcon_num {
  padding: 0 5px;
  display: inline-block;
  height: 100%;
  color: #fff;
  background-color: #dc3912;
  border-radius: 0 5px 5px 0;
}
.mapIcon:before,
.mapIcon:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: solid rgba(0, 0, 0, 0);
  border-width: 6px;
  left: 9px;
}
.mapIcon:before {
  bottom: -13px;
  border-top-color: #8e8e8e;
}
.mapIcon:after {
  bottom: -12px;
  border-top-color: #fffeef;
}
.amap-simple-marker-label {
  display: inline-flex;
}
/* 波纹圆圈 */
.example {
  position: absolute;
  top: 25px;
  left: 2px;
}
.dot {
  display: inline-block;
}
/* 颜色 */
/* let color = ['#00F04F', '#01DAFF', '#0098E9', '#F3A100', '#F7666A', '#FCE800'] */
.colorStyle0:before,
.colorStyle0:after {
  background-color: #00f04f;
}

.colorStyle1:before,
.colorStyle1:after {
  background-color: #01daff;
}

.colorStyle2:before,
.colorStyle2:after {
  background-color: #0098e9;
}

.colorStyle3:before,
.colorStyle3:after {
  background-color: #f3a100;
}

.colorStyle4:before,
.colorStyle4:after {
  background-color: #f7666a;
}

.colorStyle5:before,
.colorStyle5:after {
  background-color: #fce800;
}

.colorStyle0:after {
  box-shadow: 0 0 10px rgba(0, 240, 79, 0.3) inset;
}
.colorStyle1:after {
  box-shadow: 0 0 10px rgba(1, 218, 255, 0.3) inset;
}
.colorStyle2:after {
  box-shadow: 0 0 10px rgba(0, 152, 233, 0.3) inset;
}
.colorStyle3:after {
  box-shadow: 0 0 10px rgba(243, 161, 0, 0.3) inset;
}
.colorStyle4:after {
  box-shadow: 0 0 10px rgba(247, 102, 106, 0.3) inset;
}
.colorStyle5:after {
  box-shadow: 0 0 10px rgba(252, 232, 0, 0.3) inset;
}
.dot:before {
  content: ' ';
  position: absolute;
  z-index: 2;
  margin-left: 2px;
  top: 0;
  width: 10px;
  height: 10px;
  /* background-color: #ff4200; */
  border-radius: 50%;
}

.dot:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  /* background-color: #ff4200; */
  border-radius: 50%;
  /* box-shadow: 0 0 10px rgba(0,0,0,.3) inset; */
  -webkit-animation-name: 'ripple'; /*动画属性名，也就是我们前面keyframes定义的动画名*/
  -webkit-animation-duration: 1.5s; /*动画持续时间*/
  -webkit-animation-timing-function: ease; /*动画频率，和transition-timing-function是一样的*/
  -webkit-animation-delay: 0s; /*动画延迟时间*/
  -webkit-animation-iteration-count: infinite; /*定义循环资料，infinite为无限次*/
  -webkit-animation-direction: normal; /*定义动画方式*/
}
@keyframes ripple {
  0% {
    left: 6px;
    top: 6px;
    opcity: 85;
    width: 0;
    height: 0;
  }
  100% {
    left: -20px;
    top: -20px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}
</style>
