import request from '@/utils/http'

// 项目列表
export function queryList(params) {
  return request({
    url: '/project/pc/list',
    method: 'get',
    params
  })
}


// 获取该工地老板的工地列表
export function projectList(params) {
  return request({
    url: '/project/boss/list',
    method: 'get',
    params
  })
}




